.mp-button {
  background-color: var(--color1);
  padding: 14px;
  color: var(--blanco);
  border-radius: 20px;
  font-size: 15px;
  font-weight: 600;
  border: none;
}

.mp-button:hover {
  background-color: #095194;
}
