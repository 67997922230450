.modal-cart {
  top: 0;
  background-color: var(--blanco);
  width: 65vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px 0px 0px 10px;
  border: none;
}

.modal-send-form {
  background-color: var(--blanco);
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 1rem;
}
.modal-send-form textarea {
  background-color: var(--gris2);
  border-radius: 6px;
  padding: 13px;
  border: none;
}
.modal-send-form input {
  background-color: var(--gris2);
  border-radius: 6px;
  padding: 13px;
  border: none;
}
.overlay-cart {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.333);
  z-index: 999;
  display: flex;
  justify-content: right;
}

.modal-content-cart {
  width: 100%;
  overflow-y: scroll;
  min-height: 60vh;
  padding: 10px;
}
.modal-content-cart::-webkit-scrollbar {
  width: 5px;
  height: 5px;

  cursor: pointer;
  background-color: transparent;
}

.modal-content-cart::-webkit-scrollbar-thumb {
  background-color: var(--color1);
  border-radius: 10px;
  margin-top: 3rem;
}
.cardProductCart {
  display: flex;
  gap: 10px;
  margin-bottom: 1rem;
  position: relative;
  align-items: center;
  border-radius: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--gris2);
  justify-content: space-around;
}
.deleteToCart {
  background-color: var(--color1);
  color: var(--blanco);
  border-radius: 10px;
  border: none;
  cursor: pointer;
}
.deleteCart {
  cursor: pointer;
  color: var(--color1);
  font-weight: 500;
  background-color: transparent;
  border: none;
  font-size: 17px;
  text-align: right;
}
.cardProductCart img {
  width: 5rem;
  height: 5rem;
  border-radius: 15px;
  object-fit: cover;
}
.cardProductCartText {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 7px;
  width: 50%;
}

.cardProductCartText h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;
  font-weight: 500;
  font-size: 16px;
  color: var(--text-color2);
}
.cardProductCartText span {
  color: var(--text-color2);
  font-size: 14px;
}
.cardProductCartText strong {
  color: var(--text-color2);
  font-size: 15px;
  font-weight: 500;
}
.deFLex {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  background-color: var(--color1);
  color: var(--blanco);
  align-items: center;
}
.deFLex h4 {
  color: var(--blanco);
  font-size: 17px;
  font-weight: 400;
}
.deFLex button {
  background-color: transparent;
  border: none;
  color: var(--blanco);
  cursor: pointer;
  font-size: 17px;
}
.deFLexBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
.btnWpp {
  background-color: rgb(30, 184, 30);
  padding: 11px;
  color: var(--blanco);
  border-radius: 60px;
  border: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 10px;
  font-weight: 600;
  font-size: 15px;
}
.deColumnCart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 30%; /* <-- añadi el height pa*/
  padding: 10px;
  gap: 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.255);
  background-color: var(--blanco);
  margin: 0px 20px;
}
.btnWpp img {
  width: 25px;
}
.deColumnCart h4 {
  padding-bottom: 10px;
  color: var(--text-color2);
  border-bottom: 1px solid var(--text-color2);
}
.cartIcon {
  color: var(--color1);
  border: none;
  background-color: transparent;
  font-size: 20px;
  cursor: pointer;
}
.btn {
  background-color: var(--color1);
  padding: 15px;
  color: var(--blanco);
  border-radius: 60px;
  border: none;
  cursor: pointer;
  width: 100%;
}
.nohay {
  text-align: center;
  padding-top: 5rem;
  color: var(--text-color2);
}

.cartIconFixed {
  position: fixed;
  top: 85%;
  right: 0;
  left: 92%;
  background-color: var(--color1);
  color: var(--blanco);
  padding: 25px;
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 17px;
  z-index: 2;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.196);
}
.cartIconFixed span {
  background-color: red;
  padding: 4px 7px;
  border-radius: 100%;
  font-size: 12px;
  position: absolute;
  margin-top: -15px;
  margin-left: 1rem;
  color: var(--blanco);
}
.deFLexRadio {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  color: var(--text-color2);
  background-color: var(--gris2);
  border-radius: 6px;
  padding: 13px;
  border: none;
  font-size: 14px;
}
.deFLexRadio div {
  display: flex;
  gap: 10px;
  align-items: center;
}

.deFlexCantidad {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}
.deFlexCantidad button {
  color: var(--blanco);
  background-color: var(--color1);
  border: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 3px 6px;
}
.deFlexCantidad span {
  font-weight: 600;
}

@media (max-width: 900px) {
  .modal-cart {
    width: 100%;
    height: 100vh;
    border-radius: 0px;
  }
  .modal-content-cart::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  .cartIcon {
    color: var(--blanco);
  }
  .cartIconFixed {
    position: fixed;
    top: 90%;
    right: 0;
    left: 80%;
  }
}
