.form-container {
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  background-color: #f9f9f9;
}

.form-header {
  text-align: center;
  margin-bottom: 20px;
}

.form-header h1 {
  font-size: 24px;
  font-weight: bold;
}

.form-header p {
  color: #666;
}

.form-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-section {
  margin-bottom: 20px;
}

.form-section h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.form-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-field label {
  font-weight: 500;
}

.form-field input,
.form-field select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-radio-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-radio {
  display: flex;
  align-items: center;
  gap: 10px;
}

.form-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.form-button:hover {
  background-color: #0056b3;
}
